import { useOthersPhotos } from "shared/Hooks/others/useOthersPhotos";

export const useOthersData = () => {
  const photos = useOthersPhotos();

  return {
    metaInfo: {
      title: "Pozostali producenci",
      ogDescription:
        "WUMAX ma w swojej ofercie szeroką gamę produktów światowej czołówki producentów z branży obróbki metali. Zapraszamy do zapoznania się z katalogami przedstawiającymi innowacyjne rozwiązania narzędziowe, które możemy dla Państwa dostarczyć.",
      hashMap: {
        maford: {
          title: " - M.A. Ford",
          ogImage: photos.maford,
        },
        yg1: {
          title: " - YG1",
          ogImage: photos.yg1,
        },
        vargus: {
          title: " - Vargus",
          ogImage: photos.vargus,
        },
        zcc: {
          title: " - ZCC",
          ogImage: photos.zccusa,
        },
      },
    },
    hero: {
      title: "Zobacz także naszych pozostałych producentów",
      subtitle:
        "WUMAX ma w swojej ofercie szeroką gamę produktów światowej czołówki producentów z branży obróbki metali. Zapraszamy do zapoznania się z katalogami przedstawiającymi innowacyjne rozwiązania narzędziowe, które możemy dla Państwa dostarczyć.",
      bg: photos.hero,
    },
    maford: {
      linkTag: "maford",
      title: "M.A. Ford",
      subtitle:
        "Firma, która na całym świecie może poszczycić się reputacją producenta narzędzi o wysokiej wydajności i precyzji działania. Perfekcyjna obróbka metalu, efektywność i ekonomia - to cechy jednoznacznie kojarzące się z M.A. Ford.",
      links: [
        {
          url:
            "/catalogs/maford/MAFord_2020_Master_Catalog_Interactive.pdf",
          text: "Pobierz katalog",
        },
      ],
      bigImg: true,
      src: photos.maford,
    },
    yg1: {
      linkTag: "yg1",
      title: "YG1",
      subtitle:
        "Jeden ze światowych liderów produkcji w branży narzędzi skrawających. Długoletnie doświadczenie pozwala wprowadzać nowoczesne rozwiązania, które zapewniają wysoką wydajność przy niskich cenach.",
      links: [
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/MILLING-TOOLS.pdf",
          text: "Milling - Nowy Katalog YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/HOLEMAKING-TOOLS.pdf",
          text: "Holemaking - Nowy Katalog YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/THREADING-TOOLS.pdf",
          text: "Threading - Nowy Katalog YE20",
        },
        {
          url:
            "https://www.yg-1.pl/images/katalogi/YE-IT20_INDEXABLE_INSERT-interactive-ver1-5.pdf",
          text: "Indexable – Nowy Katalog YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/TOOLING-SYSTEM.pdf",
          text: "Tooling system – Nowy Katalog YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/Tooling_System.pdf",
          text: "Oprawki",
        },
      ],
      bigImg: true,
      src: photos.yg1,
    },
    zcc: {
      linkTag: "zcc",
      title: "ZCC",
      subtitle:
        "Wysokiej jakości narzędzia do toczenia, frezowania czy wiercenia. Cechuje je wytrzymałość i stabilność, wypracowane przez doświadczenie producenta działającego globalnie.",
      links: [
        {
          url:
            "https://www.zccct-europe.net/web/documents/Produkte/600_CAT_PL.pdf",
          text: "Katalog Główny",
        },
        {
          url:
            "https://www.zccct-europe.net/web/documents/Produkte/634_PNK_3-20_PL.pdf",
          text: "Nowe produkty",
        },
      ],
      bigImg: true,
      src: photos.zccusa,
    },
    vargus: {
      linkTag: "vargus",
      title: "VARGUS",
      subtitle:
        "To jeden ze światowych liderów produkcji wysokiej jakości narzędzi do cięcia, żłobienia i gratowania metalu oraz plastiku. Innowacja poparta doświadczeniem i renomą - tak w skrócie można określić narzędzia VARGUS.",
      links: [
        {
          url: "https://www.vargus.com/groovex-catalogs",
          text: "Katalog Groovex",
        },
        {
          url: "https://www.vargus.com/vardex-catalogs",
          text: "Katalog Vardex",
        },
        {
          url: "https://www.vargus.com/shaviv-catalogs",
          text: "Katalog Shaviv",
        },
      ],
      bigImg: true,
      src: photos.vargus,
    },
  };
};
